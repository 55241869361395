nav {
    font-family: inter;
    height: 120px;
    width: 98%;
    padding: .75em 1em;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.logo-wrapper {
    width: 400px;
    height: 120px;
    overflow: hidden;
}

.logo-wrapper > img {
    height: 140%;
    width: 140%;
    object-fit: cover;
    transform: translate(-120px, -30px);
}

ul {
    display: flex;
    text-decoration: none;
    list-style-type: none;
}

ul > li {
    color: black;
    margin: 0 .5em;
    transition: 300ms;
    border-bottom: 1px solid transparent;
    transition: .25s ease-in-out;
    cursor: pointer;
}

ul > li:hover{
    border-color: black;
}

.nav-header {
    font-size: 2em;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: .5em;
    text-align: right;
    margin: 0;
}

.link-container {
     display: flex;
     flex-direction: column;
}

.social-link-container{ 
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.social-image {
    height: 26px;
    width: 26px;
    margin: 0 .25em;
}


@media (max-width: 768px) {
    nav {
      flex-direction: row;
    }

    .logo-wrapper {
        width: 200px;
        height: 100px;
        overflow: hidden;
    }
    .logo-wrapper > img {
        height: 140%;
        width: 140%;
        object-fit: cover;
        transform: translate(-65px, -30px);
    }

  }

  @media (max-width: 375px) {
    nav {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin-bottom: 1em;
    }

    .logo-wrapper {
        width: 200px;
        height: 100px;
        overflow: hidden;
    }
    .logo-wrapper > img {
        height: 140%;
        width: 140%;
        object-fit: cover;
        transform: translate(-45px, -30px);
    }

    ul {
        display: flex;
        text-decoration: none;
        list-style-type: none;
    }
    
    ul > li {
        color: black;
        margin: 0 .25em;
        font-size: .85em;
        transition: 300ms;
        border-bottom: 1px solid transparent;
        transition: .25s ease-in-out;
        cursor: pointer;
    }

  }