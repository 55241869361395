.card-container{
    font-family: inter;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 400px;
    width: 1000px;
    margin: 1em;
    padding: 1em;
}

.card-container:nth-of-type(even){
    flex-direction: row-reverse;
    text-align: right;
}

.content-container { 
    font-size: 2em;
    height: 400px;
    width: 30%;
}

.photo-container {
    display: flex;
    flex-wrap: wrap;
    height: 400px;
    width: 70%;
}

.project-image-container, .x6, .x4{
    flex: 1 0 calc(33.33% - 10px);
    height: 190px;
    margin: 5px;
    /*** Just to center the text ***/
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.x4{
    flex: 1 0 calc(50% - 10px);
}

.x2, .x1{
height: 100%;
}

.project-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
