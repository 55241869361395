.h4{
    font-family: poppins;
    text-transform: capitalize;
    margin: 1em;
}

.h5{
    font-family: poppins;
    text-transform: capitalize;
    margin: 1em;
    letter-spacing: 3.5px;
}

.error-style{
    font-family: Inter;
    font-weight: 400;
    font-size: 3em;
    margin: 0;
}

.span{
    font-size: 6em;
    font-weight: 500;
}
