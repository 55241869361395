.container-component { 
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1em 0;
}

.delay{
    animation: lateDisplay 5s cubic-bezier(1,-0.38,1,-0.38);
}

@keyframes  lateDisplay{
    from {
        opacity: 0%;
    }
    to{
        visibility: 100%;
    }
}

@media (max-width: 768px) {
  .container-component {
    width: 100%;
  }
}

@media (max-width: 375px) {
    .container-component {
      width: 100%;
      padding: 0;
    }
  }