.download-link{
    text-decoration: none;
}

.download{
    font-family: poppins;
    font-weight: 600;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 50px;
    color: white;
    background-color: black;
    padding: 1em 3em;
    width: 200px;
    height: 50px;
    text-transform: capitalize;
    cursor: pointer;
    border: 1px solid black;
    transition: all .25s ease-in-out;
}

.download > svg {
    fill: white;
    height: 17px;
    transition: all .25s ease-in-out;
    
}
.download > svg:hover {
    fill: black;
}

.download:hover{
    background-color: white;
    color: black;
}