.large-image-container{
    height: 480px;
    width: 700px;
    animation: apparition 1.5s cubic-bezier(1,.1,1,.1); 
}

.large-image{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

@keyframes  apparition{
    from {
        opacity: 0%;
    }
    to{
        opacity: 100%;
    }
}

